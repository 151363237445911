<template>
    <div class="modal-mask">
        <div class="modal-content-container">
            <div class="edit-product-modal-title">
                Adicionar produto
                <span v-on:click="closeModal" class="material-icons modal-close-icon">cancel</span>
            </div>
            <div class="add-product-body-container">
                <div style="display: flex;">
                    <div class="ressuply-products__search-product__input-container">
                        <input placeholder="Digite o nome do produto" v-model="product_name_filter" @keyup="searchProduct()"
                            class="ressuply-products__search-product__search-input" type="text" />
                        <span @click="searchProduct()"
                            class="material-icons ressuply-products__search-product__search-icon">
                            search
                        </span>
                    </div>
                    <div @click="showNewProductModal = true" class="ressuply-products__actions__wrapper">
                        <span class="material-icons ressuply-products__actions-icon">
                            add_circle_outline
                        </span>
                        <span class="ressuply-products__actions-title">
                            Novo Produto
                        </span>
                    </div>
                </div>

                <div class="add-table-overflow">
                    <table class="ressuply-products__table-wrapper">
                    <thead class="ressuply-products__table-thead desktop">
                        <tr class="ressuply-products__table-thead-tr">
                            <td class="ressuply-products__table-td">Código</td>
                            <td class="ressuply-products__table-td" style="width: 55%;">Nome</td>
                            <td class="ressuply-products__table-td">Embalagem</td>
                            <td class="ressuply-products__table-td not-print">Quantidade</td>
                            <td class="ressuply-products__table-td not-print" style="width: 11%;">Prc. Custo</td>
                            <td class="ressuply-products__table-td not-print" style="width: 11%;">Prc. Venda</td>
                            <td class="ressuply-products__table-td not-print">salvar</td>
                        </tr>
                    </thead>
                    <tbody class="ressuply-products__table-tbody">
                        <tr v-for="(item, idx) in my_products" :key="'prod-' + item.pro_id"
                            :class="getLineClass(item, idx)" class="ressuply-products__table-tbody-tr">

                            <!-- desktop   -->
                            <td class="ressuply-products__table-td desktop">{{ item.pro_ean }}</td>
                            <td class="ressuply-products__table-td desktop">{{ item.pro_descricao }}</td>
                            <td class="ressuply-products__table-td desktop">{{ item.pro_generico == 1 ?
                                item.pro_unidade_compra : item.pro_embalagem }}</td>
                            <td class="ressuply-products__table-td not-print desktop">
                                <div class="ressuply-products__edit-amount-wrapper">
                                    <input min="0" type="number" :data-idx="idx" ref="new-amount"
                                        class="product-amount text-center  not-print focused-input"
                                        :class="{ active: item.saved }" v-model.number="item.new_amount"
                                        data-visible="true" @keydown="handle_my_products_navigation($event, item)"
                                        @blur="handle_my_products_navigation($event, item)"
                                        :key="'qtd' + item.prod_id" />
                                    <span @click="handle_my_products_navigation($event, item)"
                                        class="material-icons ressuply-products__edit-amount-icon not-print">
                                        add
                                    </span>
                                </div>
                            </td>
                            <td class="ressuply-products__table-td not-print desktop">
                                <div class="ressuply-products__edit-amount-wrapper">
                                    <money min="0"    v-bind="money"
                                        class="product-amount-2 text-center  not-print focused-input"
                                        :class="{ active: item.updated }" v-model.number="item.custo_atual"
                                        data-visible="true" :disabled="!item.saved"/>
                                </div>
                            </td>
                            <td class="ressuply-products__table-td not-print desktop">
                                <div class="ressuply-products__edit-amount-wrapper">
                                    <money min="0"   v-bind="money"
                                        class="product-amount-2 text-center  not-print focused-input"
                                        :class="{ active: item.updated }" v-model.number="item.preco_venda"
                                        data-visible="true"  :disabled="!item.saved"/>
                                </div>
                            </td>
                            <td class="ressuply-products__table-td not-print desktop">
                                <span @click="()=>saveProduct(item)" class="material-icons-outlined" :class="{'add-product-active-icon': item.saved , 'add-product-disabled-icon': !item.saved}">save</span>
                            </td>


                            <!-- mobile -->
                            <td class="mobile mobile-name-row">
                                {{ item.pro_descricao }}
                            </td>
                            <td class="mobile mobile-table-row">
                                <div>
                                    <p class="mobile-table-title">Código</p>
                                    {{ item.pro_ean }}
                                </div>
                                <div class="mobile-center">
                                    <p class="mobile-table-title">Embalagem</p>
                                    {{ item.pro_generico == 1 ? item.pro_unidade_compra : item.pro_embalagem }}
                                </div>
                                <div class="mobile-input-size mobile-end">
                                    <p class="mobile-table-title">Quantidade</p>
                                    <div class="ressuply-products__edit-amount-wrapper">
                                        <input min="0" type="number" :data-idx="idx"
                                            class="product-amount text-center not-print focused-input"
                                            :class="{ active: item.saved }" v-model.number="item.new_amount"
                                            data-visible="true" @keydown="handle_my_products_navigation($event, item)"
                                            @blur="handle_my_products_navigation($event, item)"
                                            :key="'qtd' + item.prod_id" />
                                        <span @click="handle_my_products_navigation($event, item)"
                                            class="material-icons ressuply-products__edit-amount-icon not-print">
                                            add
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="5">
                                <div class="footer" :key="'footer'">
                                    <Pagination :key="'pag'" :items_by_page_value="30" :total="total_items" :page="page"
                                        :page_limit="5" classes="orange" v-on:change="update_page($event)">
                                    </Pagination>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
                
            </div>

        </div>

        <NewProductModal v-if="showNewProductModal"
            		   :product="{}"
                   :closeDialog="() => showNewProductModal = null"
                   :categories="[]"
                   @emit_product="() => {}"
            		   @reload_products="()=>{}"
            		   @close="showNewProductModal = null" />
        <cc-loader-full v-if="isLoading"/>
    </div>
</template>

<script>
import StandardButton from '@/components/ui/buttons/Standard-Button.vue'
import QuotationService from "@/services/QuotationService";
import { typingRemoteRequestControlMixin } from "@/mixins/typing-remote-request-controls.mixin";
import Pagination from "@/components/cliente/base-components/Pagination";
import { elementNavigationmMixin } from "@/mixins/navigation.mixin";
import RequestsService from "@/services/v2/requests.service";
import NewProductModal from "@/modals/new-product/new-product.modal";


export default {
    name: 'AddProductModal',
    components: {
        StandardButton,
        Pagination,
        NewProductModal
    },
    mixins: [typingRemoteRequestControlMixin, elementNavigationmMixin],
    data() {
        return {
            my_products: [],
            service: new QuotationService(),
            svc: new RequestsService(),
            page: 0,
            total_items: 0,
            products: [],
            my_categories: undefined,
            updatePage: false,
            product_name_filter: '',
            searchTime: undefined,
            showNewProductModal: false,
            isLoading: true,
            money: {
                decimal: ',',
                prefix: 'R$ ',
                precision: 2,
            },
        }
    },
    props: {
        close: {
            type: Function
        },
        reloadPage: {
            type: Function
        },
        quotation: {
            type: Number
        },
        update:{
            type: Function
        }
    },
    mounted() {
        this.load_my_products();
    },
    methods: {
        cancelSearch() {
            if (this.cancelSource) {
                this.cancelSource.cancel(
                    "Start new search, stop active search"
                );
            }
        },
        searchProduct() {
            const time = new Date()
            this.searchTime = time
            setTimeout(() => {
                if (this.searchTime == time) this.load_my_products()
            }, 500);
        },
        getLineClass(item, idx) {
            let resp = ''
            resp += this.getSpecialBackground(idx)
            if (item.focused == true) resp += ' active'
            return resp
        },
        getSpecialBackground(idx) {
            if (idx % 2 != 0) return 'page-table-line-special'
        },
        update_page(page) {
            this.page = page;
            this.load_my_products();
        },
        handle_my_products_navigation($event, product) {
            this.navigate($event, {
                ref: 'new-amount',
                callback: {
                    function: this.add_product,
                    params: product
                }
            })
        },
        closeModal() {
            if (!this.updatePage) this.close()
            else this.reloadPage()
        },
        add_product(prod) {
            
            let data = { quantidade: prod.new_amount, prod_id: prod.pro_id, flag_generico: prod.pro_generico }

            if (isNaN(prod.new_amount) || prod.new_amount == prod.quantidade) return Promise.resolve()

            return this.svc.add_product_to_resupply_request(this.quotation, data).then(() => {
                prod.saved = true
                const notification = {
                    type: 'success',
                    message: 'Produto adicionado com sucesso!'
                }
                this.$store.dispatch('notification/add', notification)
                prod.saved = true
                prod.quantidade = prod.new_amount
                this.updatePage = true
            })
        },
        saveProduct(item){
            item.updated = true
            this.$forceUpdate()
            const notification = {
                type: 'success',
                message: 'Produto salvo com sucesso!'
            }
            this.$store.dispatch('notification/add', notification)
            this.update(item)
        },
        load_my_products() {
            this.cancelSearch(() => {
                this.isLoading = true;
            });
            const params = {
                nomeproduto: this.product_name_filter,
                page: this.page,
                deleted: 0,
            };
            this.service
                .getProdutosClientePorPagina(params, this.cancelSource?.token)
                .then((data) => {
                    this.my_products = data.data.produtos.data;

                    // data.data.categorias.forEach(element => {
                    //     if (element.cat_excluido != '1' && element.cat_status != '0') this.my_categories.push({ value: element, text: element.cat_descricao })
                    // });


                    this.page = data.data.produtos.current_page;
                    setTimeout(() => {
                        this.total_items = data.data.produtos.total;
                    }, 500);
                    //this.totalPaginas = data.data.produtos.last_page;
                    this.products.forEach((v) => (v.expanded = false));
                    this.isLoading = false;

                })
                .catch((error) => {
                    this.isLoading = false;
                });
        }
    }
}
</script>

<style scoped lang="scss">
.modal-mask {
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
    font-size: 1vw;
}

.modal-content-container {
    background-color: white;
    width: 80vw;
    border-radius: 12px;
}

.ressuply-products__search-product__input-container {
    display: flex;
    align-items: center;
}

.ressuply-products__search-product__search-icon {
    position: relative;
    font-size: 2em;
    left: -40px;
    cursor: pointer;
    color: #898989;
}

.ressuply-products__search-product__search-input {
    width: 25vw;
    color: #A1A1A1;
    font-weight: 400;
    font-size: 1.2em;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    padding: 1vw;
}
.ressuply-products__table-thead-tr{
    width: 60vw;
}

.edit-product-modal-title {
    background-color: var(--primary-color);
    display: flex;
    justify-content: space-between;
    font-size: 2.25em;
    border-radius: 10px 10px 0 0;
    color: white;
    font-weight: 500;
    letter-spacing: -0.02em;
    padding: 1vh 1vw;
    align-items: center;
}
.add-product-active-icon{
    color: lightgreen;
    cursor: pointer;
}
.add-product-disabled-icon{
    color: lightgrey;
    cursor: default;
}

.modal-close-icon {
    font-size: 1.5em;
    cursor: pointer;
}

.ressuply-products__table-td {
    font-family: Rubik;
    color: #605F5F;
    font-size: 1em;
    font-weight: 300;
}
.ressuply-products__actions__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1vw;
  margin-left: 1vw;
  width: 16vw;
  border-radius: 8px;
  border: 1px solid #E5E5E5;
  background-color: #FAFAFC;
  cursor: pointer;
}
.ressuply-products__actions-icon {
  color: var(--primary-color);
  margin-right: 1rem;
}
.ressuply-products__actions-title {
  font-weight: 400;
  font-size: 1.1vw;
  letter-spacing: 2%;
  color: #505050;
}

.ressuply-products__edit-amount-wrapper {
    display: flex;
    align-items: center;
}
.add-table-overflow{
    height: 65vh;
    overflow: auto;
    margin-top: 2vh;
}

.product-amount {
    border-radius: 5px 0px 0px 5px;
    border: 2px solid #E5E5E5;
    width: 50%;
    text-align: center;
    outline: none;
}
.product-amount-2 {
    border-radius: 5px  5px;
    border: 2px solid #E5E5E5;
    width: 90%;
    text-align: center;
    outline: none;
}
.product-amount-2.active {
    background-color: #9fff9f73;
    color: green;
}
.product-amount.active {
    background-color: #9fff9f73;
    color: green;
}

.ressuply-products__edit-amount-icon {
    color: #fff;
    background-color: #E5E5E5;
    border: 1.5px solid #E5E5E5;
    border-radius: 0px 5px 5px 0px;
    cursor: pointer;
}

.focused-input:focus-visible+.ressuply-products__edit-amount-icon {
    background-color: var(--primary-color);
}

.mobile {
    display: none;
}



.add-product-body-container {
    padding: 2vh 3vw;
}



@media only screen and (max-width: 750px) {
    .desktop {
        display: none;
    }

    .product-amount {
        width: 100%;
    }

    .mobile {
        display: block;
    }

    .modal-mask {
        font-size: 2.5vw;
    }
}
</style>