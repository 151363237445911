<template>
    <div class="resuply-page">
        <div class="page-route-select">
            <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
                Home
            </router-link>
            <span class="material-icons page-route-select-icon">play_arrow</span>
            <div class="page-route-select-text">
                Monitor de ressuprimentos
            </div>
        </div>
        <!-- <div class="page-title">Produtos do Encarte: {{ showProduct.pro_descricao }}</div> -->
         <p class="product-focus-title ">{{ showProduct.pro_descricao || '--' }}</p>
         <p class="product-focus-obs">{{ showProduct.observacao || '--' }}</p>
         <div class="product-show-info-container">
            <span>Emb: {{ showProduct.pro_generico == 1 ? showProduct.pro_unidade_compra : showProduct.pro_embalagem }}</span>
            <span>Vlr Emb: {{ showProduct.balance | twoDecimal }}</span>
            <span>Custo: <b class="info-value info-blue">{{ showProduct.custo_atual | twoDecimal }}</b> </span>
            <span>Venda: <b class="info-value info-green">{{ showProduct.preco_venda | twoDecimal }}</b></span>
            <span>Mrg: <b class="info-value info-orange">{{ showProduct.margen | formatNumber }}%</b></span>
         </div>
        <div class="resuply-buttons-row">
            <div class="mobile-search-size">
                <span class="ressuply-products__search-product__search-title">
                    Buscar Produto
                </span>
                <div class="ressuply-products__search-product__input-container">
                    <input placeholder="Digite o nome do produto" v-model="productFilter" 
                        class="ressuply-products__search-product__search-input" type="text" />
                </div>
            </div>

            <StandardButton class="mobile-button-size" iconName="add_circle_outline" :action="() => addProduct = true"
                text="Adicionar produto" />
            <StandardButton class="mobile-button-size" iconName="save" text="Salvar" />
            <StandardButton class="mobile-back-button-size" :action="() => $router.push('/cliente/monitores/ressuprimento')"
                text="Voltar" />
        </div>
        <div class="table-header">
            <div class="table-header-row">
                <div class="table-colum-align table-header-text code-width">Qtd.</div>
                <div class="table-colum-align table-header-text desc-width">Descrição</div>
                <div class="table-colum-align table-header-text text-width">$ Custo</div>
                <div class="table-colum-align table-header-text obs-width">Emb. </div>
                <div class="table-colum-align table-header-text obs-width">Margem</div>
                <div class="table-colum-align table-header-text text-width">Fornecedor</div>
                <div class="table-colum-align table-header-text action-width">Açoes</div>
            </div>
            <div class="table-header-row">
                <div class="table-colum-align table-header-text code-width">Cod.</div>
                <div class="table-colum-align table-header-text desc-width">Obs. </div>
                <div class="table-colum-align table-header-text text-width">$ Venda</div>
                <div class="table-colum-align table-header-text obs-width">$ Valor Emb.</div>
                <div class="table-colum-align table-header-text obs-width"></div>
                <div class="table-colum-align table-header-text text-width">Vendedor</div>
            </div>

        </div>
        <div class="table-body-container">
            <div v-for="(p, i) in showProducts" :key="i" class="table-row">

                <!-- desktop -->
                <div class="table-header-row desktop">
                    <div class="table-colum-align table-row-text code-width">
                        <div class="table-header-text mobile">Quantidade</div>
                        <div class="ressuply-products__edit-amount-wrapper">
                            <input min="0" :id="'input-quantity-' + i" type="number" :disabled="!storeId"
                                v-model="p.quantidade" @focus="() => showProduct = p" @change="()=>p.changed=true" @keyup.enter="updateProduct(p)" @keydown="moveFocus($event, i, p)"
                                class="product-amount text-center focused-input"
                                :class="{ active: p.saved == true, error: p.error }" />
                            <span @click="updateProduct(p)"
                                class="material-icons ressuply-products__edit-amount-icon not-print">
                                add
                            </span>
                        </div>
                    </div>
                    <div class="table-colum-align table-row-text desc-width">
                        <div class="table-header-text mobile">Descrição</div>
                        {{ p.pro_descricao }}
                    </div>
                    <div class="table-colum-align table-row-text text-width">
                        <div class="table-header-text mobile">Custo</div>
                        {{ p.custo_atual | twoDecimal }}
                    </div>
                    <div class="table-colum-align table-row-text obs-width">
                        <div class="table-header-text mobile">Embalagem</div>
                        {{ p.pro_generico == 1 ? p.pro_unidade_compra : p.pro_embalagem }}
                        <i class="far fa-edit" @click="edit_wrap(p)"
                            v-if="can('PRODUCT', 'DELETE') && p.pro_generico == 0"></i>
                    </div>

                    <div class="table-colum-align table-row-text obs-width">
                        <div class="table-header-text mobile">Margem</div>
                        {{ p.margen | formatNumber }}%
                    </div>
                    <div class="table-colum-align table-row-text text-width">
                        <div class="table-header-text mobile">Fornecedor</div>
                        ?
                    </div>
                    <div class="table-colum-align table-header-text action-width">
                        <span v-if="storeId" style="color: lightcoral; cursor: pointer;" @click="remove(p)"
                            class="material-icons-outlined">delete</span>
                        <span v-if="storeId" style="color: lightblue; cursor: pointer;" @click="openUpdateModal(p)"
                            class="material-icons-outlined">edit</span>
                    </div>
                </div>
                <div class="table-header-row desktop">
                    <div class="table-colum-align table-row-text code-width">
                        <div class="table-header-text mobile">Código</div>
                        {{ p.pro_ean }}
                    </div>

                    <div class="table-colum-align table-row-text desc-width" :class="{ 'obs-color': p.observacao }">
                        <div class="table-header-text mobile">Observação</div>
                        {{ p.observacao || '--' }}
                    </div>
                    <div class="table-colum-align table-row-text text-width">
                        <div class="table-header-text mobile">Venda</div>
                        {{ p.preco_venda | twoDecimal }}
                    </div>
                    <div class="table-colum-align table-row-text obs-width">
                        <div class="table-header-text mobile">Valor Emb.</div>
                        {{ p.balance | twoDecimal }}
                    </div>
                    <div class="table-colum-align table-row-text obs-width"></div>
                    <div class="table-colum-align table-row-text text-width">
                        <div class="table-header-text mobile">Vendedor</div>
                        ?
                    </div>
                </div>

                <!-- mobile -->
                <div class="mobile">
                    <p class="table-header-text">{{ p.pro_descricao }}</p>
                    <div class="mobile-row">
                        <span class="table-row-text" style="color: lightcoral;">{{ p.observacao || '--' }}</span>
                        <span class="table-row-text">{{ p.pro_ean }}</span>
                    </div>
                    <div class="mobile-row">
                        <div class="mobile-row-cell mobile-row-left">
                            <span class="table-header-text">Custo</span>
                            <span class="table-row-text info-value info-blue">{{ p.custo_atual | twoDecimal }}</span>
                        </div>
                        <div class="mobile-row-cell">
                            <span class="table-header-text">Preço Venda</span>
                            <span class="table-row-text info-value info-green">{{ p.preco_venda | twoDecimal }}</span>
                        </div>
                        <div class="mobile-row-cell mobile-row-right">
                            <span class="table-header-text">Margem</span>
                            <span class="table-row-text info-value info-orange">{{ Math.floor(p.margen) }}%</span>
                        </div>
                    </div>
                    <div class="mobile-row">
                        <div class="mobile-row-cell mobile-row-left">
                            <span class="table-header-text">Embalagem</span>
                            <span class="table-row-text">{{ p.pro_generico == 1 ? p.pro_unidade_compra :
                                p.pro_embalagem}}</span>
                        </div>
                        <div class="mobile-row-cell">
                            <span class="table-header-text">Valor Emb.</span>
                            <span class="table-row-text">{{ p.balance | twoDecimal }}</span>
                        </div>
                        <div class="mobile-row-cell mobile-row-right">
                            <span class="table-header-text">Quantidade</span>
                            <span class="table-row-text">{{ p.quantidade }}</span>
                        </div>
                    </div>
                    <div style="display: flex; justify-content: space-between; margin-top: 2vh; ">
                        <div class="ressuply-products__edit-amount-wrapper">
                            <input min="0" :id="'input-quantity-' + i" type="number" :disabled="!storeId"
                                v-model="p.quantidade" @focus="() => showProduct = p" @change="()=>p.changed=true" @keyup.enter="updateProduct(p)" @keydown="moveFocus($event, i, p)"
                                class="product-amount text-center focused-input"
                                :class="{ active: p.saved == true, error: p.error }" />
                            <span @click="updateProduct(p)"
                                class="material-icons ressuply-products__edit-amount-icon not-print">
                                add
                        </span>
                        </div>
                        <span v-if="storeId" style="color: lightcoral; cursor: pointer;" @click="remove(p)"
                            class="material-icons-outlined">delete</span>
                        <span v-if="storeId" style="color: lightblue; cursor: pointer; width: 25%; text-align: end;" @click="openUpdateModal(p)"
                            class="material-icons-outlined">edit</span>
                    </div>
                </div>

            </div>
        </div>
        


        <cc-loader-full v-if="isLoading" />
        <ProductWrapModal v-if="current_product" :product="current_product"
            @close="current_product = false; isLoading = true; loadProducts()" />
        <EditProductModal v-if="editProduct" :update="updateProduct" :product="editProduct"
            :close="() => editProduct = undefined" />
        <AddProductModal v-if="addProduct" :close="() => addProduct = false" :reloadPage="reloadPage"
            :quotation="this.resuply.numeroCotacao" :update="updateProduct" />
    </div>
</template>

<script>
import StandardButton from '@/components/ui/buttons/Standard-Button.vue'
import QuotationService from "@/services/QuotationService";
import currencyPrecision from '../../mixins/currencyPrecision';
import { perm_mixin } from '@/mixins/permission.mixin'
import ProductWrapModal from "@/modals/product-wrap/product-wrap.modal"
import EditProductModal from './EditProductModal.vue';
import AddProductModal from './AddProductModal.vue';

export default {
    name: 'EditSpecialResuplyPage',
    data() {
        return {
            resuply: this.$store.getters.editResuply,
            storeId: localStorage.getItem('store_id'),
            service: new QuotationService(),
            products: [],
            isLoading: true,
            current_product: undefined,
            editProduct: undefined,
            addProduct: undefined,
            showProduct: {},
            productFilter: ''
        }
    },
    mixins: [currencyPrecision, perm_mixin],
    components: {
        StandardButton,
        ProductWrapModal,
        EditProductModal,
        AddProductModal
    },
    computed: {
        showProducts() {
            if (this.productFilter) return this.products.filter(p => p.pro_descricao.toLowerCase().includes(this.productFilter.toLowerCase()))
            return this.products
        }
    },
    methods: {
        getSpecialBackground(idx) {
            if (idx % 2 != 0) return 'page-table-line-special'
        },
        remove(product) {
            this.service.remove_product_ressuply(this.resuply.numeroCotacao, product.pro_id)
                .then(() => {
                    // this.loadProducts()
                }).catch(error => {
                    ErrorHandlerService.handle(error, this.$store);
                });
        },
        edit_wrap(product) {
            product.embalagem = product.pro_embalagem
            product.descricaoProduto = product.pro_descricao
            this.current_product = product
        },
        moveFocus(e, i, p) {
            let index = false
            if (e.key == "ArrowDown") index = i + 1
            else if (e.key == "ArrowUp") index = i - 1
            const input = document.getElementById('input-quantity-' + index)
            if (input) {
                e.preventDefault()
                input.focus()
                input.select()
                this.updateProduct(p)
            }
        },
        openUpdateModal(product) {
            this.editProduct = product
            this.showProduct = product
        },
        updateProduct(product) {
            console.log(1);
            
            if (!this.storeId || !product.changed) return
            console.log(2);
            
            if (product.quantidade == 0){
                this.remove(product)
                return
            }
            this.editProduct = false
            return this.service.updateQuotationProductAmount(this.resuply.numeroCotacao, product).then(() => {
                product.saved = true
                product.changed = false
                product.balance = (product.custo_atual || 0) * (product.pro_generico == 1 ? product.quantidade : product.pro_embalagem.split(' ')[1])
                if (product.preco_venda && product.custo_atual) product.margen = ((product.preco_venda / product.custo_atual || 0) - 1) * 100
                else product.margen = 0
                this.$forceUpdate()
            })
        },
        loadProducts() {
            this.service.getProductsResupply(this.resuply.numeroCotacao).then(resp => {
                let products = resp.data.produtos
                products.forEach(p => {
                    p.balance = (p.custo_atual || 0) * (p.pro_generico == 1 ? p.quantidade : p.pro_embalagem.split(' ')[1])
                    if (p.preco_venda && p.custo_atual) p.margen = ((p.preco_venda / p.custo_atual || 0) - 1) * 100
                    else p.margen = 0
                });
                this.products = products
                this.isLoading = false
            })
        },
        reloadPage() {
            this.isLoading = true
            this.addProduct = false
            this.loadProducts()
        }
    },
    mounted() {
        this.loadProducts()
    },
}
</script>

<style scoped lang="scss">
.resuply-page {
    padding-right: 10vw;
    padding-bottom: 15vh;
}

.obs-color {
    color: lightcoral !important;
}

.page-route-select {
    display: flex;
    align-items: center;
}

.page-route-select-text {
    font-weight: 400;
    font-size: 1.36em;
    color: #ABABAB;
    margin-right: 20px;
    cursor: pointer;
}

.page-route-select-icon {
    color: var(--primary-color);
    margin-right: 20px;
}

.page-title {
    font-weight: 700;
    font-size: 2.72em;
    letter-spacing: 0.15px;
    color: #605F5F;
    margin-top: 40px;
}

.resuply-buttons-row {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 2vw;
}

.table-header {
    margin-top: 6vh;
    margin-bottom: 2vh;
    border: 0.5px solid #E5E5E5;
    padding: 1vh 0;
}

.table-header-row {
    display: flex;
}

.table-colum-align {
    padding-left: 2vw;
}

.code-width {
    width: 7%;
}

.desc-width {
    width: 50%;
}

.prc-width {
    width: 8%;
}

.text-width {
    width: 9%;
}

.obs-width {
    width: 9%;
}

.action-width {
    width: 5%;
    display: flex;
}

.table-header-text {
    font-style: normal;
    font-weight: 600;
    font-size: 1.1em;
    line-height: 17px;
    color: #605F5F;
}

.checkbox-icon-containear {
    margin-left: 0.5vw;
    padding-right: 0.5vw;
    color: var(--primary-color);
    cursor: pointer;
    font-size: 1.5em;
}

.edit-table-row {
    padding: 0.5vw 0;
    border-bottom: 1px solid lightgray;
}

.edit-table-row:hover {
    background-color: rgb(232, 232, 232);
}

.fa-edit {
    margin-left: 10px;
    color: var(--primary-color);
    cursor: pointer;
}

.ressuply-products__edit-amount-wrapper {
    display: flex;
    align-items: center;
}

.ressuply-products__edit-amount-icon {
    color: #fff;
    background-color: #E5E5E5;
    border: 1.5px solid #E5E5E5;
    border-radius: 0px 5px 5px 0px;
    cursor: pointer;
}

.focused-input:focus-visible+.ressuply-products__edit-amount-icon {
    background-color: var(--primary-color);
}

.product-amount {
    border-radius: 5px 0px 0px 5px;
    border: 2px solid #E5E5E5;
    width: 100%;
    text-align: center;
    outline: none;
}

.product-amount.active {
    background-color: #9fff9f73;
    color: green;
}
.mobile-search-size{
    flex: 1;
}

.table-row-text {
    font-weight: 300;
    font-size: 1em;
    line-height: 17px;
    color: #605F5F;
}
.product-focus-title{
    text-align: center;
    font-weight: 600;
    font-size: 1.7em;
    color: #605F5F;
}
.product-focus-obs{
    text-align: center;
    font-weight: 600;
    font-size: 1.4em;
    color: lightcoral;
    margin: 0;
}
.product-show-info-container{
    display: flex;
    justify-content: space-evenly;
    span{
        text-align: center;
        font-weight: 600;
        font-size: 1.4em;
        color: #605F5F;
        width: 17%;
    }
}
.info-value{
    color: white;
    padding: 0 5%;
    border-radius: 5px;
}
.info-blue{
    background-color: lightblue;
}
.info-green{
    background-color: lightgreen;
}
.info-orange{
    background-color: lightsalmon;
}

.ressuply-products__search-product__input-container {
    display: flex;
    align-items: center;
}

.ressuply-products__search-product__search-icon {
    position: absolute;
    font-size: 2em;
    right: 25px;
    cursor: pointer;
    color: #898989;
}

.ressuply-products__search-product__search-input {
    width: 25vw;
    color: #A1A1A1;
    font-weight: 400;
    font-size: 1.2em;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    padding: 1vw;
}

.ressuply-products__search-product__search-title {
    color: #898989;
    font-size: 1.5em;
    font-weight: 400;
    letter-spacing: 0.15px;
    margin-bottom: 0.5vh;
}
.table-body-container{
    overflow: auto;
    height: 40vh;
}

.table-row {
    padding: 1.5vh 0;
    border-bottom: 1px solid rgb(226, 225, 225);
}

.mobile {
    display: none;
}

@media only screen and (max-width: 850px) {
    .table-header {
        display: none;
    }

    .table-row {
        margin-top: 5vw;
    }

    .table-header-row {
        display: flex;
        flex-direction: column;
        padding-right: 2%;
    }

    .table-colum-align {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 2vw;
    }

    .mobile {
        display: unset;
    }

    .page-title {
        font-size: 1.5em;
    }

    .desktop {
        display: none;
    }

    .mobile-row {
        display: flex;
        justify-content: space-between;
    }

    .mobile-row-cell {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 30%;
        margin-top: 1vh;
    }

    .mobile-row-left {
        align-items: flex-start;
        text-align: left;
    }

    .mobile-row-right {
        align-items: flex-end;
        text-align: right;
    }
    .ressuply-products__edit-amount-wrapper {
        width: 25%;
    }

    .resuply-page {
        padding-left: 5vw;
        padding-right: 5vw;
    }

    .mobile-button-size {
        width: 45%;
    }
    .mobile-back-button-size{
        width: 100%;
    }

    .resuply-buttons-row {
        justify-content: space-between;
    }
    .product-show-info-container{
        justify-content: space-between;
        flex-wrap: wrap;
        span{
            width: 45%;
            text-align: unset;
        }
    }
    .resuply-buttons-row{
        flex-wrap: wrap;
    }
    .ressuply-products__search-product__search-input{
        width: 100%;
    }
    .table-body-container{
        height: unset;
    }
    .product-focus-title{
        display: none;
    }
    .product-focus-obs{
        display: none;
    }
    .product-show-info-container{
        display: none;
    }
    .mobile-search-size{
        width: 100%;
        flex: unset;
        margin-top: 5vh;
    }
}
</style>